import React, { Component } from 'react'
import { Modal, Button, Form, Message, Transition } from 'semantic-ui-react'
import axios from 'axios'

class NewsletterSignup extends Component {
  state = {
    email: '',
    firstName: '',
    lastName: '',
    walkNumber: '',
    loading: false,
    success: false,
    errors: [],
  }

  // Handle input value changes
  handleInputChange = e => this.setState({ [e.target.name]: e.target.value })

  handleSubmit = async e => {
    e.preventDefault()
    this.setState({ loading: true, errors: [], success: false })
    await this.registerEmail()
  }

  registerEmail = async () => {
    const { email, firstName, lastName, walkNumber } = this.state

    try {
      const result = await axios.post(
        'https://api.sendgrid.com/v3/contactdb/recipients',
        [
          {
            email,
            first_name: firstName,
            last_name: lastName,
            walk_number: walkNumber,
          },
        ],
        {
          headers: {
            authorization: `Bearer ${process.env.GATSBY_SENDGRID_API_KEY}`,
          },
        }
      )

      const {
        data: { errors },
      } = result

      if (errors.length > 0) {
        return this.setState({ errors, loading: false })
      }

      return this.setState({
        success: true,
        email: '',
        firstName: '',
        lastName: '',
        walkNumber: '',
        loading: false,
      })
    } catch (err) {
      console.error(err)
    }
  }

  render() {
    const { email, firstName, lastName, walkNumber, loading, success, errors } = this.state

    return (
      <Modal
        trigger={
          <Button positive size="large">
            Newsletter Signup
          </Button>
        }
        size="tiny"
        closeIcon
      >
        <Modal.Header>Newsletter Signup</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <Form onSubmit={this.handleSubmit} loading={loading}>
              <Transition visible={success} animation="fade" duration={400}>
                <Message
                  success
                  header="Done!"
                  content="You're all signed up for the newsletter."
                />
              </Transition>
              <Transition visible={errors.length > 0} animation="fade" duration={400}>
                <Message error>
                  <Message.Header>Error</Message.Header>
                  <Message.Content>
                    <ul>
                      {errors.length > 0 &&
                        errors.map(({ message }) => <li key={message}>{message}</li>)}
                    </ul>
                  </Message.Content>
                </Message>
              </Transition>
              <Form.Field
                label="First Name"
                control="input"
                name="firstName"
                id="firstName"
                value={firstName}
                onChange={this.handleInputChange}
                required
              />
              <Form.Field
                label="Last Name"
                control="input"
                name="lastName"
                id="lastName"
                value={lastName}
                onChange={this.handleInputChange}
                required
              />
              <Form.Field
                label="Email"
                control="input"
                name="email"
                id="email"
                type="email"
                value={email}
                onChange={this.handleInputChange}
                required
              />
              <Form.Field
                label="Walk Number"
                control="input"
                name="walkNumber"
                id="walkNumber"
                type="number"
                step="1"
                value={walkNumber}
                onChange={this.handleInputChange}
                required
              />
              <Button type="submit" positive>
                Submit
              </Button>
            </Form>
          </Modal.Description>
        </Modal.Content>
      </Modal>
    )
  }
}

export default NewsletterSignup
