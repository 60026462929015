import { graphql, Link } from 'gatsby';
import React from 'react';
import { Button } from 'semantic-ui-react';
import Layout from '../../components/layout';
import NewsletterSignup from '../../components/NewsletterSignup';
import PortableTextContent from '../../components/portableTextContent';
import PrivateRoute from '../../components/privateRoute';
import { DownloadLink, LinkWrapper } from '../../components/ui/links';
import { NewsletterContentWrapper } from '../../templates/newsletter';

const NewsIndex = ({ data }) => {
  const newsletter = data.newsletters.edges[0].node;

  return (
    <Layout
      headerImage={data.headerImage.childImageSharp}
      headerText="News"
      headerSubtext={<NewsletterSignup />}
      headerSize="sm"
    >
      <h2>{newsletter.title}</h2>
      <NewsletterContentWrapper>
        <PortableTextContent rawContent={newsletter._rawContent} />
      </NewsletterContentWrapper>
      <LinkWrapper>
        <DownloadLink
          href={newsletter.file.asset.url}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Button positive>Download</Button>
        </DownloadLink>
      </LinkWrapper>
      <LinkWrapper>
        <Link to="/news/archive">
          <Button color="blue">View Newsletter Archive</Button>
        </Link>
      </LinkWrapper>
    </Layout>
  );
};

export default props => {
  return <PrivateRoute component={NewsIndex} {...props} />;
};

export const query = graphql`
  query LatestNewsletterQuery {
    newsletters: allSanityNewsletter(
      sort: { fields: publishDate, order: DESC }
      limit: 1
    ) {
      edges {
        node {
          id
          title
          publishDate
          file {
            asset {
              url
            }
          }
          _rawContent
        }
      }
    }

    headerImage: file(relativePath: { eq: "letters.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 1600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
